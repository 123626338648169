import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { WINDOW } from '@inshared/shared/util';

@Injectable({ providedIn: 'root' })
export class NativeRouterService {
    private readonly window = inject<Window>(WINDOW);
    private readonly document = inject<Document>(DOCUMENT);

    public get baseUrl(): string {
        return this.window.location.origin;
    }

    public navigateByUrl(href: string): void {
        this.document.location.href = href;
    }

    public navigate(url: string[]): void {
        const href = url.join('/');
        this.document.location.href = href;
    }
}
