import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@inshared/shared/util';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

export const DEVICES = {
    ANDROID: 'Android',
    I_PAD: 'iPad',
    IPHONE: 'iPhone',
    I_POD: 'iPod',
    BLACKBERRY: 'Blackberry',
    FIREFOX_OS: 'Firefox-OS',
    CHROME_BOOK: 'Chrome-Book',
    WINDOWS_PHONE: 'Windows-Phone',
    PS4: 'PS4',
    VITA: 'Vita',
    CHROMECAST: 'Chromecast',
    APPLE_TV: 'Apple-TV',
    GOOGLE_TV: 'Google-TV',
    UNKNOWN: 'Unknown',
};

@Injectable({
    providedIn: 'root',
})
export class DeviceDetectorService {
    public userAgent = '';
    public device: string = DEVICES.UNKNOWN;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(WINDOW) private window: Window) {
        if (isPlatformBrowser(this.platformId)) {
            this.setUserAgent();
            this.setDevice();
        }
    }

    public isMobile(): boolean {
        const mobiles = [
            DEVICES.ANDROID,
            DEVICES.IPHONE,
            DEVICES.I_POD,
            DEVICES.BLACKBERRY,
            DEVICES.FIREFOX_OS,
            DEVICES.WINDOWS_PHONE,
            DEVICES.VITA,
        ];
        return mobiles.includes(this.device);
    }

    public isTablet(): boolean {
        const tablets = [DEVICES.I_PAD, DEVICES.FIREFOX_OS];
        return tablets.includes(this.device);
    }

    public isIOS(): boolean {
        const tablets = [DEVICES.I_PAD, DEVICES.IPHONE, DEVICES.I_POD];
        return tablets.includes(this.device);
    }

    private setUserAgent(): void {
        if (this.window) {
            this.userAgent = this.window.navigator.userAgent;
        }
    }

    private setDevice(): void {
        const devicesArray = Object.keys(DEVICES).map((k) => DEVICES[k]);
        this.device = devicesArray.reduce((previousValue, currentValue) => {
            return new RegExp(currentValue).test(this.userAgent) ? currentValue : previousValue;
        }, DEVICES.UNKNOWN);
    }
}
