export const mockWindow: Partial<Window> = {
    open: (url?: string | URL, target?: string, features?: string): null => {
        return null;
    },
    location: {
        origin: '',
        href: '',
        ancestorOrigins: undefined,
        hash: '',
        host: '',
        hostname: '',
        pathname: '',
        port: '',
        protocol: '',
        search: '',
        assign: (url: string | URL): void => {
            return null;
        },
        reload: (): void => {
            return null;
        },
        replace: function (url: string | URL): void {
            return null;
        },
    },
}
