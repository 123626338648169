import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { SchemaTypeEnum } from '../enums';
import { ArticleSchemaInterface, FaqSchemaInterface, ProductSchemaInterface } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class StructuredDataService {
    private renderer: Renderer2 | undefined;
    private window: Window | null;
    private scriptType = 'application/ld+json';

    constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView;
    }

    public insertProductSchema = (product: ProductSchemaInterface): void => {
        // https://schema.org/Product
        const productSchema = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.name,
            description: product.description,
            brand: {
                '@type': 'Brand',
                name: product.brand.name,
            },
            image: product.images,
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: product.aggregateRating.ratingValue,
                reviewCount: product.aggregateRating.reviewCount,
                bestRating: product.aggregateRating.bestRating || '10',
            },
        };
        this.insertSchema(productSchema, SchemaTypeEnum.Product);
    };

    public insertFaqSchema = (faqItems: FaqSchemaInterface[]): void => {
        // https://schema.org/FAQPage
        const mainEntity = faqItems.map((item) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer,
            },
        }));

        const faqSchema = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity,
        };

        this.insertSchema(faqSchema, SchemaTypeEnum.Faq);
    };

    public insertArticleSchema = (article: ArticleSchemaInterface): void => {
        // https://schema.org/Article
        const articleSchema = {
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: article.headline,
            image: [article.image],
            datePublished: article.publishedDate,
            dateModified: article.modifiedDate,

            publisher: {
                name: 'Inshared',
                url: 'https://www.inshared.nl',
            },
        };
        this.insertSchema(articleSchema, SchemaTypeEnum.Article);
    };

    private insertSchema(schema: Record<string, any>, type: SchemaTypeEnum): void {
        const src = JSON.stringify(schema);

        this.renderer = this.rendererFactory.createRenderer(null, null);

        const script = this.renderer.createElement('script');

        this.renderer.setAttribute(script, 'type', this.scriptType);
        this.renderer.setAttribute(script, 'data-structured-data', type);
        script.text = src;

        if (!this.document.querySelectorAll(`[data-structured-data=${type}]`).length)
            this.document.head.appendChild(script);
    }

    public removeSchema(): void {
        this.document.querySelectorAll('[data-structured-data]').forEach((el) => el.remove());
    }
}
