import { Injectable, Inject, EventEmitter, PLATFORM_ID, ElementRef } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {
    constructor(
        private pageScrollService: PageScrollService,
        private route: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: string,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public scrollToFragment(delay: number = 0, offset: number = 0): Observable<void> {
        return this.route.fragment.pipe(map((fragment) => {
            if (fragment) {
                this.scrollElementOffset(`#${fragment}`, delay, offset);
            }
        }));
    }

    public scrollElementOffset(element: string, delay: number = 0, offset: number = 0): EventEmitter<boolean> {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        if (offset === 0) {
            offset = this.getOffset();
        }

        const eventEmitter = new EventEmitter<boolean>();

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: element,
                scrollOffset: offset,
                scrollFinishListener: eventEmitter
            });
            clearTimeout(pageScrollTimeout);
        }, delay);

        return eventEmitter;
    }

    public scrollToElement(element: string | HTMLElement, delay: number = 0, scrollingView: ElementRef = null): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const options = {
            document: this.document,
            scrollTarget: element
        };

        if (scrollingView) {
            options['scrollViews'] = [ scrollingView.nativeElement ];
            options['advancedInlineOffsetCalculation'] = true;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll(options);
            clearTimeout(pageScrollTimeout);
        }, delay);
    }

    public scrollToTop(delay: number = 0): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: 'body'
            });
            clearTimeout(pageScrollTimeout);
        }, delay);
    }

    public goToHeadingInContainer(nativeElement: any): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: '.panel__mutatie.scroll',
                scrollViews: [nativeElement]
            });
            clearTimeout(pageScrollTimeout);
        }, 2000);
    }

    public goToClass(className: string): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: className
            });
            clearTimeout(pageScrollTimeout);
        }, 0);
    }

    private getOffset(): number {
        const stickybar = this.document.querySelector('.section--sticky');
        if (!stickybar) {
            return 0;
        }
        return stickybar.clientHeight;
    }
}
