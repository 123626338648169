import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';
import { mockWindow } from './window.mock';
import { isPlatformServer } from '@angular/common';
export const WINDOW = new InjectionToken<Window | Partial<Window>>('WindowToken');

function getWindow(platformId: string): Window | Partial<Window> {
    return isPlatformServer(platformId) ? mockWindow : window;
}

export const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: getWindow,
    deps: [PLATFORM_ID]
};
