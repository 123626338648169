import { inject, Injectable } from '@angular/core';
import { AppRoutesEnum, AvgRoutesEnum, CarInsuranceRoutesEnum, MyZoneRoutesEnum } from '@inshared/shared/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { BreadcrumbItemInterface } from '../interfaces';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    private currentBreadcrumbs: BehaviorSubject<BreadcrumbItemInterface[]> = new BehaviorSubject<{
        title: string;
        routerLink?: string
    }[]>([]);

    public breadcrumbs$: Observable<BreadcrumbItemInterface[]> = this.currentBreadcrumbs.asObservable();

    public router: Router = inject(Router);

    public static ABOUT_EVERYTHINGONLINE_TITLE = 'Het gemak van alles online';
    public static ABOUT_TITLE = 'Over InShared';
    public static ACCIDENTINSURANCE_TITLE = 'Ongevallenverzekering';
    public static ANNUALREWARD_MOSTRECENT_TITLE = 'Jaarbeloning 2021';
    public static ANNUALREWARD_TITLE = 'De Jaarbeloning van InShared';
    public static AVG_RIGHTS_TITLE = 'Mijn privacy';
    public static BICYCLEINSURANCE_TITLE = 'Fietsverzekering';
    public static BUILDINGINSURANCE_TITLE = 'Opstalverzekering';
    public static CARAVANINSURANCE_TITLE = 'Caravanverzekering';
    public static CARINSURANCE_ALLRISK_TITLE = 'All risk autoverzekering';
    public static CARINSURANCE_BRANDS_TITLE = 'Automerken';
    public static CARINSURANCE_EV_TITLE = 'Elektrische auto verzekeren';
    public static CARINSURANCE_LIABILITY_TITLE = 'All risk autoverzekering';
    public static CARINSURANCE_TITLE = 'Autoverzekering';
    public static DAMAGEREPAIR_TITLE = 'Schadeherstel';
    public static DAMAGE_TITLE = 'Schade';
    public static HOMEINSURANCE_TITLE = 'Woonverzekering';
    public static HOME_TITLE = 'Home';
    public static INSURANCES_TITLE = 'Verzekeringen';
    public static INVENTORYINSURANCE_TITLE = 'Inboedelverzekering';
    public static LEGALINSURANCE_TITLE = 'Rechtsbijstandverzekering';
    public static LIABILITYINSURANCE_TITLE = 'Aansprakelijkheidsverzekering';
    public static MOPEDINSURANCE_TITLE = 'Brommerverzekering';
    public static MOTORINSURANCE_TITLE = 'Motorverzekering';
    public static MYZONE_DAMAGES_TITLE = 'Mijn schades';
    public static MYZONE_TITLE = 'Mijn Verzekeringsmap';
    public static PETINSURANCE_TITLE = 'Dierenverzekering';
    public static PRIVACY_TITLE = 'Mijn privacy';
    public static SCOOTERINSURANCE_TITLE = 'Scooterverzekering';
    public static TRAVELINSURANCE_TITLE = 'Reisverzekering';
    public static VACANCIES_TITLE = 'Werken bij';
    public static PREVENTION_TITLE = 'Preventie';

    public static HOME = {
        title: BreadcrumbService.HOME_TITLE,
        routerLink: '/',
    };

    public static ABOUT = {
        title: BreadcrumbService.ABOUT_TITLE,
        routerLink: `/${AppRoutesEnum.About}`,
    };
    public static ABOUT_EVERYTHINGONLINE = {
        title: BreadcrumbService.ABOUT_EVERYTHINGONLINE_TITLE,
        routerLink: `/${AppRoutesEnum.About}/${AppRoutesEnum.EverythingOnline}`,
    };
    public static ACCIDENTINSURANCE = {
        title: BreadcrumbService.ACCIDENTINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.AccidentInsurance}`,
    };
    public static ANNUALREWARD = {
        title: BreadcrumbService.ANNUALREWARD_TITLE,
        routerLink: `/${AppRoutesEnum.AnnualReward}`,
    };
    public static ANNUALREWARD_MOSTRECENT = {
        title: BreadcrumbService.ANNUALREWARD_MOSTRECENT_TITLE,
        routerLink: `/${AppRoutesEnum.AnnualReward}/${AppRoutesEnum.AnnualRewardMostRecent}`,
    };
    public static AVG_RIGHTS = {
        title: BreadcrumbService.AVG_RIGHTS_TITLE,
        routerLink: `/${AppRoutesEnum.Avg}/${AvgRoutesEnum.Rights}`,
    };
    public static BICYCLEINSURANCE = {
        title: BreadcrumbService.BICYCLEINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.BicycleInsurance}`,
    };
    public static BUILDINGINSURANCE = {
        title: BreadcrumbService.BUILDINGINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.BuildingInsurance}`,
    };
    public static CARINSURANCE = {
        title: BreadcrumbService.CARINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.CarInsurance}`,
    };
    public static CARINSURANCE_ALLRISK = {
        title: BreadcrumbService.CARINSURANCE_ALLRISK_TITLE,
        routerLink: `/${AppRoutesEnum.CarInsurance}/${CarInsuranceRoutesEnum.AllRisk}`,
    };
    public static CARINSURANCE_BRANDS = {
        title: BreadcrumbService.CARINSURANCE_BRANDS_TITLE,
        routerLink: `/${AppRoutesEnum.CarInsurance}/${CarInsuranceRoutesEnum.Brands}`,
    };
    public static CARINSURANCE_EV = {
        title: BreadcrumbService.CARINSURANCE_EV_TITLE,
        routerLink: `/${AppRoutesEnum.CarInsurance}/${CarInsuranceRoutesEnum.EVInsurance}`,
    };
    public static CARINSURANCE_LIABILITY = {
        title: BreadcrumbService.CARINSURANCE_LIABILITY_TITLE,
        routerLink: `/${AppRoutesEnum.CarInsurance}/${CarInsuranceRoutesEnum.Liability}`,
    };
    public static DAMAGE = {
        title: BreadcrumbService.DAMAGE_TITLE,
        routerLink: `/${AppRoutesEnum.Damage}`,
    };
    public static HOMEINSURANCE = {
        title: BreadcrumbService.HOMEINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.HomeInsurance}`,
    };
    public static INSURANCES = {
        title: BreadcrumbService.INSURANCES_TITLE,
        routerLink: `/${AppRoutesEnum.Insurances}`,
    };
    public static INVENTORYINSURANCE = {
        title: BreadcrumbService.INVENTORYINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.InventoryInsurance}`,
    };
    public static LEGALINSURANCE = {
        title: BreadcrumbService.LEGALINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.LegalInsurance}`,
    };
    public static MOPEDINSURANCE = {
        title: BreadcrumbService.MOPEDINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.MopedInsurance}`,
    };
    public static SCOOTERINSURANCE = {
        title: BreadcrumbService.SCOOTERINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.ScooterInsurance}`,
    };
    public static MYZONE = {
        title: BreadcrumbService.MYZONE_TITLE,
        routerLink: `/${AppRoutesEnum.MyZone}`,
    };
    public static MYZONE_DAMAGES = {
        title: BreadcrumbService.MYZONE_DAMAGES_TITLE,
        routerLink: `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.MyClaims}`,
    };
    public static PETINSURANCE = {
        title: BreadcrumbService.PETINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.PetInsurance}`,
    };
    public static PRIVACY = {
        title: BreadcrumbService.PRIVACY_TITLE,
        routerLink: `/${AppRoutesEnum.Privacy}`,
    };
    public static TRAVELINSURANCE = {
        title: BreadcrumbService.TRAVELINSURANCE_TITLE,
        routerLink: `/${AppRoutesEnum.TravelInsurance}`,
    };
    public static VACANCIES = {
        title: BreadcrumbService.VACANCIES_TITLE,
        routerLink: `/${AppRoutesEnum.Vacancies}`,
    };
    public static PREVENTION = {
        title: BreadcrumbService.PREVENTION_TITLE,
        routerLink: `/${AppRoutesEnum.Prevention}`,
    };

    // TODO: change to routerLink
    public static CARAVANINSURANCE = {
        title: BreadcrumbService.CARAVANINSURANCE_TITLE,
        href: `/${AppRoutesEnum.CaravanInsurance}`,
    };
    public static DAMAGEREPAIR = {
        title: BreadcrumbService.DAMAGEREPAIR_TITLE,
        href: `/${AppRoutesEnum.DamageRepair}`,
    };
    public static LIABILITYINSURANCE = {
        title: BreadcrumbService.LIABILITYINSURANCE_TITLE,
        href: `/${AppRoutesEnum.LiabilityInsurance}`,
    };
    public static MOTORINSURANCE = {
        title: BreadcrumbService.MOTORINSURANCE_TITLE,
        href: `/${AppRoutesEnum.MotorInsurance}`,
    };

    constructor() {
        this.router.events.pipe(
            filter((event) => (event instanceof NavigationEnd))
        ).subscribe((event) => this.setBreadcrumb([]));
    }

    public setBreadcrumb(crumbs: BreadcrumbItemInterface[]): void {
        this.currentBreadcrumbs.next(crumbs);
    }

}
