import { isSafari } from "../browser-detector/browser-detector-utilities";

export class PdfDownloadUtilities {

    public static OpenPdfTargetBlank(url: string, windowRef: Window): void {
        windowRef.open(url, '_blank');
    }

    public static OpenPdfBase64TargetBlank(base64String: string, mimeType: string, windowRef: Window): void {
        PdfDownloadUtilities.ShowDocument(base64String, mimeType, windowRef);
    }

    private static Base64ToArrayBuffer(base64String: string, windowRef: Window): Uint8Array {
        const binaryString = windowRef.atob(base64String);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    private static ShowDocument(base64String: string, contentType: string, windowRef: Window): void {
        const byte = PdfDownloadUtilities.Base64ToArrayBuffer(base64String, windowRef);
        const blob = new Blob([byte], { type: contentType });

        // Safari polyfill for opening blobs in window
        if (isSafari(windowRef)) {
            const data = URL.createObjectURL(blob);
            const link = windowRef.document.createElement('a');
            link.href = data;
            link.download = data.substr(data.lastIndexOf('/') + 1);
            link.click();
        } else {
            windowRef.open(URL.createObjectURL(blob), '_blank');
        }
    }
}
