import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiProductIdEnum, RequestService } from 'outshared-lib';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from '@inshared/shared/util-storage';
import { Router } from '@angular/router';
import { CollectiviteitData, CollectivityPartnersEnum } from '@inshared/shared/core';

@Injectable()
export class CollectivityService {
    constructor(
        // private request: ApiService,
        private request: RequestService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(SessionStorageService) private sessionStorage: Storage,
    ) {
    }

    static STORAGE_KEY: string = 'collectiviteit';
    static API_URL: string = 'partner-algemeen/opvragen/collectiviteit-partner-subdomein';
    // static API_URL: string = 'en-gb/partner-general/request/collectivity-partner-subdomain';

    private deviatingData: { [key: string]: Partial<CollectiviteitData> } = {
        [CollectivityPartnersEnum.Hema]: {
            percentage: 10,
            isMinimalPercentage: true,
            percentages: {
                default: 12.5,
                [ApiProductIdEnum.Car]: 10
            }
        }
    };

    public getCollectivityData(subDomain: string): Observable<CollectiviteitData> {
        console.log('getCollectivityData', subDomain);
        const payload = {
            partner: {
                subdomein: subDomain
            }
        };

        return this.request.post(CollectivityService.API_URL, payload).pipe(
            map(response => response.data),
            map((data: CollectiviteitData): CollectiviteitData => ({
                ...data,
                id: subDomain,
                percentage: 5,
                ...this.deviatingData[data.partner_id],
            }))
        );
    }

    public get storedData(): CollectiviteitData | null {
        if (!isPlatformBrowser(this.platformId)) {
            return null;
        }
        try {
            return JSON.parse(this.sessionStorage.getItem(CollectivityService.STORAGE_KEY));
        } catch {
            return null;
        }

    }

    public storeData(data: CollectiviteitData): void {
        if (isPlatformBrowser(this.platformId)) {
            this.sessionStorage.setItem(CollectivityService.STORAGE_KEY, JSON.stringify(data));
        }
    }

    public get hasCollectivityData(): boolean {
        return !!this.storedData;
    }
}
